<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-3">My agency</h2>
                            <p v-if="getAgencyError" class="custom-error mb-3 ml-3"> {{ getAgencyError }} </p>
                            <div v-if="getUserAgencies.length == 0 && getAgenciesPending && !loader" class="profile__content mb-3">
                                <b-row class="mt-1">
                                    <b-col cols="5">
                                        <p class="no-results">No agency</p>
                                    </b-col>
                                    <b-col cols="7">
                                        <router-link to="/add-agency" class="details-button details-button--red float-right mt-0"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Add agency</router-link>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-if="getUserAgencies.length == 0 && loader" class="profile__content mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div v-else class="profile__content">
                                <b-row>
                                    <b-col v-for="agency in getUserAgencies" :key="agency.id" cols="12" lg="7" xl="6">
                                        <div class="profile__element mt-2">
                                            <p class="profile__label">Agency status</p>
                                            <p v-if="agency.status == 1" class="profile__status profile__status--positive">verified</p>
                                            <p v-if="agency.status == 2" class="profile__status profile__status--pending">pending</p>
                                            <p v-if="agency.status == 3" class="profile__status profile__status--negative">denied</p>
                                            <p v-if="agency.status == 3 && agency.reason" class="profile__label mt-2">Reason:{{ agency.reason }}</p>
                                        </div>
                                        <div class="agencies__element">
                                            <div v-if="agency.stability" class="agencies__stats">
                                                <span class="agencies__star-tooltip" v-b-tooltip title="Statistics included"><simple-line-icons icon="chart" size="small" color="#504e70" /></span>
                                            </div>
                                            <div v-if="agency.leader" class="agencies__leader">
                                                <span class="agencies__star-tooltip" v-b-tooltip title="Market leader"><simple-line-icons icon="diamond" size="small" color="#504e70" /></span>
                                            </div>
                                            <div v-if="agency.ribbon" class="custom-ribbon custom-ribbon--right">
                                                <span class="custom-ribbon__text custom-ribbon__text--blue">Featured</span>
                                            </div>
                                            <div class="agencies__image" v-lazy:background-image="agency.url"></div>
                                            <div class="agencies__content">
                                                <h2 class="agencies__title" v-line-clamp:24="2">{{ agency.name }}</h2>
                                                <p v-html="agency.desc" class="agencies__text" v-line-clamp:24="3"></p>
                                                <div v-if="agency.rating" class="agencies__rating agencies__rating--active">
                                                    <b-row>
                                                        <b-col cols="12">
                                                            <span :id="agency.id" class="agencies__star-tooltip"><star-rating :star-size="18" border-color="transparent" :show-rating="false" :rating="agency.rating" :increment="0.01" :read-only="true"></star-rating></span>
                                                        </b-col>
                                                        <b-tooltip :target="agency.id">
                                                            Rating: {{ agency.rating }}
                                                        </b-tooltip>
                                                    </b-row>
                                                </div>
                                                <div v-else class="agencies__rating"></div>
                                                <b-row>
                                                    <b-col cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Minimum project size"><simple-line-icons icon="tag" size="small" color="#504e70" /></span>{{ agency.project.text }}</span>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Employees"><simple-line-icons icon="people" size="small" color="#504e70" /></span>{{ agency.employees.text }}</span>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Founded"><simple-line-icons icon="flag" size="small" color="#504e70" /></span>{{ agency.founded }}</span>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Location"><simple-line-icons icon="locationPin" size="small" color="#504e70" /></span>{{ agency.city }}, {{ agency.country.text }}</span>
                                                    </b-col>
                                                    <b-col v-if="agency.email" cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="E-mail"><simple-line-icons icon="envelope" size="small" color="#504e70" /></span><a class="agencies__email-link" :href="'mailto:'+ agency.email">{{ agency.email }}</a></span>
                                                    </b-col>
                                                    <b-col v-if="agency.phone" cols="12">
                                                        <span class="agencies__info"><span class="agencies__star-tooltip" v-b-tooltip title="Phone number"><simple-line-icons icon="phone" size="small" color="#504e70" /></span>{{ agency.phone }}</span>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                            <div class="agencies__details">
                                                <b-row>
                                                    <b-col cols="12" sm="6">
                                                        <a class="agencies__button agencies__button--website" :href="agency.website" target="_blank"><simple-line-icons icon="globe" size="small" color="#504e70" />Visit website</a>
                                                    </b-col>
                                                    <b-col cols="12" sm="6">
                                                        <router-link :to="{ name: 'my-agency', params: { id: agency.id } }" class="agencies__button agencies__button--profile"><simple-line-icons icon="grid" size="small" color="#fff" />View profile</router-link>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                        <div class="profile__element mt-2">
                                            <b-row>
                                                <b-col cols="12" sm="4">
                                                    <router-link :to="{ name: 'my-agency', params: { id: agency.id } }" class="details-button details-button--full details-button--green mt-0"><simple-line-icons icon="eye" size="small" color="#fff" />Preview</router-link>
                                                </b-col>
                                                <b-col cols="12" sm="4">
                                                    <router-link :to="{ name: 'edit-agency', params: { id: agency.id } }" class="details-button details-button--full details-button--yellow mt-0"><simple-line-icons icon="note" size="small" color="#fff" />Edit</router-link>
                                                </b-col>
                                                <b-col cols="12" sm="4">
                                                    <button @click.prevent="deleteAgency(agency.id, agency.image)" :disabled="getDisabledAgency" :class="getDisabledAgency ? 'disabled-element' : ''" class="details-button details-button--full details-button--dark-red mt-0"><simple-line-icons icon="close" size="small" color="#fff" />Delete</button>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-col>
                                 </b-row>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import StarRating from 'vue-star-rating'
import Spinner from 'vue-simple-spinner'
import { mapGetters } from 'vuex'
export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    StarRating,
    Spinner,
  },
  data: function () {
    return {
      loader: true
    }
  },
  methods: {
    deleteAgency (id, imageName) {
      this.$swal({
        type: 'warning',
        title: 'Warning',
        text: 'Are you sure you want to delete this agency?',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        confirmButtonColor: '#f1352e',
        cancelButtonColor: '#13b81b',
        focusCancel: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('setDisabledAgency')
          this.$store.dispatch('deleteAgency', { id: id, image: imageName })
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.$store.dispatch('clearDisabledAgency')
            })
        }
      })
    }
  },
  computed: {
    ...mapGetters([
      'getAgencyError',
      'getDisabledAgency',
      'getUserAgencies',
      'getAgenciesPending'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAgency')
    this.$store.dispatch('getUserAgencies')
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 5000)
  }
}
</script>
